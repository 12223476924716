<template>
  <div v-if="company" id="company-detail">
    <CRow>
      <CCol>
        <CRow class="title pb-2">
          <CCol col="auto pl-0">
            <h2>
              {{ company.human_name }}
            </h2>
          </CCol>
          <CCol col="auto pr-0">
            <RWidgetStatus :status="company.status" />
          </CCol>
        </CRow>
        <CRow class="pt-4">
          <CCol>
            <CCard class="contact">
              <CCardHeader>
                Contact Info
                <RChangeRequestButton :before-open="setContactSupportFlag" />
              </CCardHeader>
              <CCardBody>
                <div class="help pb-4">
                  If our team needs to get in touch with your company, we'll use the
                  contact information below.
                </div>
                <div class="contacts">
                  <div class="section-title pb-2">
                    Main Contacts
                  </div>
                  <ul>
                    <li v-for="contact in uniqueContacts" :key="contact.id" class="pb-2">
                      <div class="contact-name">
                        {{ contact.name }}
                      </div>
                      <div v-if="contact.email">
                        {{ contact.email }}
                      </div>
                      <div v-if="contact.phone">
                        {{ contact.phone }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="email-domains">
                  <div class="section-title pb-2">
                    Company Email Domains
                  </div>
                  <ul>
                    <li v-for="domain in companyData.email_domains" :key="domain">
                      {{ domain }}
                    </li>
                  </ul>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { RChangeRequestButton } from '@/components/buttons'
import { RWidgetStatus } from '@/components/widgets'
import { CraigslistAPI, RooofAPI } from '@/services/api/resources'
import { copyToClipboard, isEmptyObject } from '@/utils'

export default {
  name: 'CompanyDetail',
  components: {
    RChangeRequestButton,
    RWidgetStatus
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      activationCode: '',
      companyData: {},
      copyButtonText: 'Copy Code'
    }
  },
  computed: {
    uniqueContacts () {
      if (isEmptyObject(this.companyData)) {
        return []
      }
      const decisionMakers = this.companyData.decision_makers
      const emergencyContacts = this.companyData.emergency_contacts.filter(contact => {
        return decisionMakers.find(other => other.id === contact.id) === undefined
      })
      return decisionMakers.concat(emergencyContacts)
    }
  },
  created () {
    this.fetchCompanyData()
    this.getActivationCode()
  },
  methods: {
    /**
     * Fetch the full company data from the api.
     */
    async fetchCompanyData () {
      const response = await RooofAPI.companies.retrieve({ id: this.$route.params.cid })
      if (response) {
        this.companyData = response.data
      }
    },
    /**
     * Get the activation code of the PostEngine group of company
     */
    async getActivationCode () {
      const params = new URLSearchParams()
      params.append('company_id', this.$route.params.cid)
      const response = await CraigslistAPI.groups.list({ params })
      if (response) {
        this.activationCode = response.data.length ? response.data[0].activation_code : 'Unknown'
      }
    },
    /**
     * Copy the activation code to clipboard.
     */
    copyActivationCode () {
      copyToClipboard('activation-code')
      this.copyButtonText = 'Copied!'
      setTimeout(() => {
        this.copyButtonText = 'Copy Code'
      }, 1200)
    },
    /**
     * Sets the "My company/property information is incorrect" checkbox
     * in the Contact Support modal.
     */
    setContactSupportFlag () {
      this.$store.commit('support/set', ['incorrect_company_property_info', true])
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin: auto;
  border-bottom: 2px solid $gray-100;
  justify-content: space-between;
  align-items: center;
}
.status-widget {
  font-weight: bold;
  font-size: 1rem;
}
.card-header {
  font-weight: 800;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
}
.card-body {
  color: $gray-700;

  .section-title {
    color: $secondary;
    font-weight: bold;
    font-size: 1.1rem;
  }
  #activation-code {
    font-size: 1rem;
  }
  ul {
    list-style-type: none;
    padding-left: 0;

    .contact-name {
      font-weight: bold;
    }
  }
}
</style>
